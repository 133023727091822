import Home from "./views/Home"
import React from 'react';

function App() {
  return (
    <div className="App">
      <Home></Home>
    </div>
  );
}

export default App;
