import classname from "classnames";
import yellow_grid from "../public/images/yellow_grid.svg";
import hero_image from "../public/images/HeroImage.jpg";
import sparkle from "../public/images/Sparkle.png";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

class Home extends React.Component {
  render() {
    return (
      <div className={classname("Home")}>
        <img
          className={classname(
            "w-full",
            "object-cover",
            "h-32",
            "relative",
            "z-0",

            "md:h-52"
          )}
          src={yellow_grid}
          alt="background-yellow-grid"
        ></img>

        <div className={classname("container", "relative", "z-10")}>
          <p
            id="title"
            className={classname(
              "font-mono",
              "text-4xl",
              "relative",
              "-top-8",

              "md:text-7xl",
              "md:-top-20"
            )}
          >
            Kevin Ngo
          </p>
          <div className="pt-2 md:pt-0 md:relative md:-top-6 md:grid md:grid-cols-2 md:gap-24 md:pb-6">
            <div id="hero-image" className="relative ">
              <div class="bg-pink rounded-lg relative top-2 right-2">
                <img
                  className="rounded-lg relative bottom-2 left-2"
                  src={hero_image}
                  alt="hero"
                ></img>
              </div>
              <img
                className="absolute top-0 right-0 -top-12 right-10"
                src={sparkle}
                alt="sparkle"
              ></img>
              <img
                className="absolute top-0 right-0 top-7 -right-OnePointFour rotate-20"
                src={sparkle}
                alt="sparkle"
              ></img>
            </div>
            <div
              id="content"
              className="pt-8 md:text-2xl md:flex md:flex-col md:items-center md:justify-center"
            >
              <div>What's up!</div>
              <div className="py-4">
                My name is Kevin and i’m a lot of things. I'm currently spending
                my time at{" "}
                <a
                  className="italic text-linkedin-blue hover:underline underline md:no-underline"
                  href="https://linkedin.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
                , but besides that, I love skateboarding, playing music, gaming,
                and of course, coding. Feel free to hit me or connect up below!
              </div>
              <div id="social-icons" className="text-center py-2 pb-4">
                <a
                  className="text-linkedin-blue"
                  href="https://www.linkedin.com/in/kevinkkngo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    className="text-5xl mx-4"
                    icon={faLinkedin}
                  />
                </a>
                <a
                  className="text-github-black"
                  href="https://github.com/Kevin-Ngo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon className="text-5xl mx-4" icon={faGithub} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
